import Row from "../components/Row";
import Stage from "../components/Stage";
import requests from "../service/requests";

function Filme() {
  return (
    <>
      <Stage title="Gerade Angesagt" fetchUrl={requests.fetchTopPopular} filme />
      <Row title="Action Filme" fetchUrl={requests.fetchActionMovies} large />
      <Row title="Comendy Filme" fetchUrl={requests.fetchComedyMovies} />
      <Row title="Horror Filme" fetchUrl={requests.fetchHorroMovies} />
      <Row title="Dokumentationen" fetchUrl={requests.fetchDocumentaries} />
      <Row title="Liebes Filme" fetchUrl={requests.fetchRomanceMovies} />
    </>
  );
}

export default Filme;
