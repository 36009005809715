import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Search from "./Search";

function Nav() {
  const [header, setHeader] = useState(false);

  const navTransition = () => {
    if (window.scrollY > 100) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", navTransition);
    return () => {
      window.removeEventListener("scroll", navTransition);
    };
  }, []);

  return (
    <header className={`${header ? "header-black" : "header-transparent"}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-3 logo">
            <h6>
              <Link to="/">
                Movie <span>Room</span>
              </Link>
            </h6>
          </div>
          <div className="col-md-6">
            <nav>
              <ul>
                <li>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/filme">
                    Filme
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/serien">
                    Serien
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/personen">
                    Personen
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <Search />
              </div>
              {/* <div className="col-md-2 text-right">
                <Link to="/profil" className="profil-link">
                  <i className="fas fa-user-circle"></i>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Nav;
