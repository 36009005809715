import Row from "../components/Row";
import Stage from "../components/Stage";
import requests from "../service/requests";

function Home() {
  return (
    <>
      <Stage title="Trends der Woche" fetchUrl={requests.fetchTrending} start />
      <Row title="Netflix Originals" fetchUrl={requests.fetchNetflixOriginals} large serie />
      <Row title="Action Filme" fetchUrl={requests.fetchActionMovies} />
      <Row title="Comendy Filme" fetchUrl={requests.fetchComedyMovies} />
      <Row title="Horror Filme" fetchUrl={requests.fetchHorroMovies} />
      <Row title="Serien" fetchUrl={requests.fetchTvPopular} serie />
      <Row title="Liebes Filme" fetchUrl={requests.fetchRomanceMovies} />
    </>
  );
}

export default Home;
