import Persons from "../components/Persons";
import requests from "../service/requests";

function Personen() {
  return (
    <>
      <Persons title="Beliebte Personen" fetchUrl={requests.fetchPersons} />
    </>
  );
}

export default Personen;
