import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import Filme from "./pages/Filme";
import Serien from "./pages/Serien";
import Personen from "./pages/Personen";
import DetailPerson from "./pages/DetailPerson";
import DetailFilm from "./pages/DetailFilm";
import DetailSerie from "./pages/DetailSerie";
// import Login from "./pages/Login";
// import PrivateRoute from "./service/PrivateRoute";
import Registrieren from "./pages/Registrieren";
import Profil from "./pages/Profil";
// import { useAuth } from "./service/auth";

function MovieRoom() {
  // const { currentUser } = useAuth();

  return (
    <Router>
      <Nav />
      {/* {currentUser && <Nav />} */}
      <Switch>
        <Route path="/registrieren" component={Registrieren} />
        {/* <Route path="/login" component={Login} /> */}
        <Route path="/" exact component={Home} />
        <Route path="/profil" component={Profil} />
        <Route path="/filme/:id" component={DetailFilm} />
        <Route path="/filme" component={Filme} />
        <Route path="/serien/:id" component={DetailSerie} />
        <Route path="/serien" component={Serien} />
        <Route path="/personen/:id" component={DetailPerson} />
        <Route path="/personen" component={Personen} />
        {/* <PrivateRoute path="/" exact component={Home} />
        <PrivateRoute path="/profil" component={Profil} />
        <PrivateRoute path="/filme/:id" component={DetailFilm} />
        <PrivateRoute path="/filme" component={Filme} />
        <PrivateRoute path="/serien/:id" component={DetailSerie} />
        <PrivateRoute path="/serien" component={Serien} />
        <PrivateRoute path="/personen/:id" component={DetailPerson} />
        <PrivateRoute path="/personen" component={Personen} /> */}
      </Switch>
      {/* {currentUser && <Footer />} */}
      <Footer />
    </Router>
  );
}

export default MovieRoom;
