import axios from "../service/axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_KEY } from "../service/requests";
import Loadingspinner from "../components/Loadingspinner";

function DetailPerson() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [personDetails, setPersonDetails] = useState([]);

  let { id } = useParams();

  const fetchUrl = `/person/${id}?api_key=${API_KEY}&language=de-DE`;

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setPersonDetails(request.data);
      setIsLoaded(true);
      return request;
    }

    fetchData();
  }, [fetchUrl]);

  const image_url = "https://image.tmdb.org/t/p/w500";
  const release = new Date(personDetails.birthday);
  const options = { year: "numeric", month: "long", day: "numeric" };

  return (
    <section className="details">
      <div className="container">
        <div className="row">
          {isLoaded ? (
            <>
              <div className="col-md-4">
                {personDetails?.profile_path === null ? (
                  <img
                    src="https://via.placeholder.com/500"
                    alt="Placeholder"
                  />
                ) : (
                  <img
                    src={`${image_url}${personDetails?.profile_path}`}
                    alt={personDetails.name}
                  />
                )}
              </div>

              <div className="col-md-8">
                <h4 className="name">{personDetails.name}</h4>
                <ul>
                  <li>
                    <i className="fas fa-birthday-cake"></i>{" "}
                    {release.toLocaleDateString("de-DE", options)}
                  </li>
                  {personDetails.place_of_birth ? (
                    <li>
                      <i className="fas fa-house-user"></i>{" "}
                      {personDetails.place_of_birth}
                    </li>
                  ) : null}
                </ul>
                <h4>Biographie</h4>
                {personDetails.biography === "" ? (
                  <p>Leider kein Text vorhanden.</p>
                ) : (
                  <p>{personDetails.biography}</p>
                )}
              </div>
            </>
          ) : (
            <Loadingspinner />
          )}
        </div>
      </div>
    </section>
  );
}

export default DetailPerson;
