import { useState } from "react";
import { Link } from "react-router-dom";

function Search() {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchInputChanges = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    if (inputValue.length > 0) {
      fetch(
        `https://api.themoviedb.org/3/search/multi?api_key=f0a43ae517dc027a6a56939bbdf786c1&language=de-DE&query=${inputValue}`
      )
        .then((response) => response.json())
        .then((jsonResponse) => {
          setSearchResults(jsonResponse.results);
        });
    } else {
      setSearchResults([]);
    }
  };

  const resetSearch = () => {
    setSearchResults([]);
    setSearchValue("");
  };

  return (
    <div className="search-box">
      <div className="search-box__input">
        <div className="search-box__input__inner">
          <input
            value={searchValue}
            onChange={handleSearchInputChanges}
            type="text"
            placeholder="Filme / Serien / Personen"
          />
          <i className="fas fa-search"></i>
        </div>
      </div>
      {searchResults.length > 0 && (
        <div className="results">
          {searchResults.map((item, index) => (
            <div className="results-wrap" key={index}>
              {item.media_type === "person" && (
                <div
                  className={
                    item.media_type === "person" && "results-box person"
                  }
                >
                  <Link
                    to={`/personen/${item.id}`}
                    onClick={() => resetSearch()}
                  >
                    <i className="fas fa-user"></i>
                    {item.name || item.title || item.orginal_name}
                  </Link>
                </div>
              )}
              {item.media_type === "tv" && (
                <div className={item.media_type === "tv" && "results-box tv"}>
                  <Link to={`/serien/${item.id}`} onClick={() => resetSearch()}>
                    <i className="fas fa-tv"></i>
                    {item.name || item.title || item.orginal_name}
                  </Link>
                </div>
              )}
              {item.media_type === "movie" && (
                <div
                  className={item.media_type === "movie" && "results-box movie"}
                >
                  <Link to={`/filme/${item.id}`} onClick={() => resetSearch()}>
                    <i className="fas fa-film"></i>
                    {item.name || item.title || item.orginal_name}
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Search;
