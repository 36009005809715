import { Link } from "react-router-dom";

function Footer() {
  let date = new Date();

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h6>
              <Link to="/">
                Movie <span>Room</span>
              </Link>
            </h6>
          </div>
          <div className="col-md-6 right">
            <p>Copyright {date.getFullYear()} - Movie Room</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
