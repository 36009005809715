import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_KEY } from "../service/requests";
import Loadingspinner from "../components/Loadingspinner";
import SimpleBar from "simplebar-react";

function DetailSerie() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [serieDetails, setSerieDetails] = useState([]);
  const [serieCredits, setCredits] = useState([]);

  let { id } = useParams();

  const fetchUrl = `https://api.themoviedb.org/3/tv/${id}?api_key=${API_KEY}&language=de-DE`;
  const fetchUrl2 = `https://api.themoviedb.org/3/tv/${id}/credits?api_key=${API_KEY}&language=de-DE`;

  useEffect(() => {
    Promise.all([
      fetch(fetchUrl).then((value) => value.json()),
      fetch(fetchUrl2).then((value) => value.json()),
    ]).then((response) => {
      setSerieDetails(response[0]);
      setCredits(response[1]);
      setIsLoaded(true);
    });
  }, [fetchUrl, fetchUrl2]);

  const image_url = "https://image.tmdb.org/t/p/w500";
  const release = new Date(
    serieDetails?.release_date || serieDetails?.first_air_date
  );
  const options = { year: "numeric", month: "long", day: "numeric" };

  return (
    <section className="details">
      <div className="container">
        <div className="row">
          {isLoaded ? (
            <>
              <div className="col-md-4">
                {serieDetails?.backdrop_path === null ? (
                  <img
                    src="https://via.placeholder.com/500"
                    alt="Placeholder"
                  />
                ) : (
                  <img
                    src={`${image_url}${
                      serieDetails?.poster_path || serieDetails?.backdrop_path
                    }`}
                    alt={serieDetails.name}
                  />
                )}
                {serieDetails.created_by.length !== 0 ? (
                  <>
                    <h4 className="blue">Regisseur</h4>
                    <ul className="person">
                      {serieDetails.created_by.map((person, index) => (
                        <li key={index}>{person.name}</li>
                      ))}
                    </ul>
                  </>
                ) : null}
                <h4 className="blue">Genres</h4>
                <ul className="genres">
                  {serieDetails.genres.map((genre, index) => (
                    <li key={index}>{genre.name}</li>
                  ))}
                </ul>
              </div>

              <div className="col-md-8">
                <h4 className="name">
                  {serieDetails?.title ||
                    serieDetails?.name ||
                    serieDetails?.original_name}
                </h4>
                <ul className="movie-details">
                  <li>
                    <i className="fas fa-star"></i> {serieDetails?.vote_average}
                  </li>
                  <li>
                    <i className="fas fa-heart"></i> {serieDetails?.vote_count}
                  </li>
                  <li>
                    <i className="fas fa-calendar-alt"></i>{" "}
                    <span className="stage__release">
                      {release.toLocaleDateString("de-DE", options)}
                    </span>
                  </li>
                  <li>Stafeln: {serieDetails?.number_of_seasons}</li>
                  <li>Episoden: {serieDetails?.number_of_episodes}</li>
                </ul>
                <h4>Handlung</h4>
                {serieDetails.overview === "" ? (
                  <p>Leider kein Text vorhanden.</p>
                ) : (
                  <p>{serieDetails.overview}</p>
                )}
                <h4>Produktionsfirmen</h4>
                {serieDetails.overview === "" ? (
                  <p>Leider kein Text vorhanden.</p>
                ) : (
                  <ul className="firm">
                    {serieDetails.production_companies.map((firm, index) => (
                      <li key={index}>{firm.name}</li>
                    ))}
                  </ul>
                )}
                <h4>Cast</h4>
                {serieCredits.cast.length === 0 ? (
                  <p>Leider keine Daten vorhanden.</p>
                ) : (
                  <div className="cast-wrapper">
                    <SimpleBar>
                      <ul className="cast">
                        {serieCredits.cast.map((cast, index) => (
                          <li key={index}>
                            <Link to={`/personen/${cast.id}`}>
                              <div className="cast__img">
                                {cast?.profile_path === null ? (
                                  <img
                                    src="https://via.placeholder.com/500x750"
                                    alt="Placeholder"
                                  />
                                ) : (
                                  <img
                                    src={`${image_url}${cast?.profile_path}`}
                                    alt={cast.name}
                                  />
                                )}
                              </div>
                              <div className="cast__name">
                                {cast?.title ||
                                  cast?.name ||
                                  cast?.original_name}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </SimpleBar>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Loadingspinner />
          )}
        </div>
      </div>
    </section>
  );
}

export default DetailSerie;
