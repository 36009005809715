import axios from "../service/axios";
import { useEffect, useState } from "react";
import Loadingspinner from "./Loadingspinner";
import { Modal } from "react-bootstrap";
import ModalTv from "./ModalTv";
import ModalMovie from "./ModalMovie";

function Stage({
  fetchUrl,
  start = false,
  serien = false,
  filme = false,
  title,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [movie, setMovie] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setMovie(
        request.data.results[
          Math.floor(Math.random() * request.data.results.length)
        ]
      );
      setIsLoaded(true);

      return request;
    }

    fetchData();
  }, [fetchUrl]);

  const shortText = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const image_url = "https://image.tmdb.org/t/p/original";
  const release = new Date(movie?.release_date || movie?.first_air_date);
  const options = { year: "numeric" };

  return (
    <section
      className={`${start ? "stage start" : "stage"}`}
      style={{ backgroundImage: `url("${image_url}${movie?.backdrop_path}")` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 stage__content">
            {isLoaded ? (
              <>
                <h3>{title}</h3>
                <div className="stage__title">
                  <h1>{movie?.title || movie?.name || movie?.original_name}</h1>
                </div>
                <div className="stage__info">
                  <ul>
                    <li>
                      <i className="fas fa-calendar-alt"></i>{" "}
                      <span className="stage__release">
                        {release.toLocaleDateString("de-DE", options)}
                      </span>
                    </li>
                    <li>
                      <i className="fas fa-star"></i> {movie?.vote_average}
                    </li>
                    <li>
                      <i className="fas fa-heart"></i> {movie?.vote_count}
                    </li>
                    {start ? (
                      <li>
                        <i className="fas fa-tv"></i>{" "}
                        {movie?.media_type === "tv" ? "Serie" : "Film"}
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div className="stage__text">
                  {movie.overview === "" ? (
                    <p>Leider kein Text vorhanden.</p>
                  ) : (
                    <p className="description">
                      {shortText(movie?.overview, 250)}
                    </p>
                  )}

                  <div className="button" onClick={handleShow}>
                    Details <i className="fas fa-plus"></i>
                  </div>

                  <Modal show={show} onHide={handleClose}>
                    <div className="close-movie-modal" onClick={handleClose}>
                      <i className="fas fa-times"></i>
                    </div>
                    {start ? (
                      <>
                        {movie?.media_type === "tv" ? (
                          <ModalTv itemId={movie.id} />
                        ) : (
                          <ModalMovie itemId={movie.id} />
                        )}
                      </>
                    ) : null}
                    {serien ? <ModalTv itemId={movie.id} /> : null}
                    {filme ? <ModalMovie itemId={movie.id} /> : null}
                  </Modal>
                </div>
              </>
            ) : (
              <>
                <Loadingspinner />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stage;
