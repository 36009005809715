import firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDFlp4bXTSBAp7dFdP8FrRNmJmvFOhqpeU",
  authDomain: "movie-room-dbe4c.firebaseapp.com",
  projectId: "movie-room-dbe4c",
  storageBucket: "movie-room-dbe4c.appspot.com",
  messagingSenderId: "697048736427",
  appId: "1:697048736427:web:4144bbc64739fc17730dee",
});

export const auth = app.auth();
export default app;
