export const API_KEY = "f0a43ae517dc027a6a56939bbdf786c1";

const requests = {
  fetchTrending: `/trending/all/week?api_key=${API_KEY}&language=de-DE`,
  fetchNetflixOriginals: `/discover/tv?api_key=${API_KEY}&with_networks=213&language=de-DE`,
  fetchSkyCinema: `/discover/tv?api_key=${API_KEY}&with_networks=877&language=de-DE`,
  fetchDisneyPlus: `/discover/tv?api_key=${API_KEY}&with_networks=2739&language=de-DE`,
  fetchJoyn: `/discover/tv?api_key=${API_KEY}&with_networks=3155&language=de-DE`,
  fetchAmazon: `/discover/tv?api_key=${API_KEY}&with_networks=1024&language=de-DE`,
  fetchTopPopular: `/movie/popular?api_key=${API_KEY}&language=de-DE`,
  fetchTvPopular: `/tv/popular?api_key=${API_KEY}&language=de-DE`,
  fetchActionMovies: `/discover/movie?api_key=${API_KEY}&with_genres=28&language=de-DE`,
  fetchComedyMovies: `/discover/movie?api_key=${API_KEY}&with_genres=35&language=de-DE`,
  fetchHorroMovies: `/discover/movie?api_key=${API_KEY}&with_genres=27&language=de-DE`,
  fetchRomanceMovies: `/discover/movie?api_key=${API_KEY}&with_genres=10749&language=de-DE`,
  fetchDocumentaries: `/discover/movie?api_key=${API_KEY}&with_genres=99&language=de-DE`,
  fetchPersons: `/person/popular?api_key=${API_KEY}&language=de-DE`,
};

export default requests;
