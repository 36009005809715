import axios from "../service/axios";
import { useEffect, useState } from "react";
import { API_KEY } from "../service/requests";
import Loadingspinner from "./Loadingspinner";
import { Link } from "react-router-dom";

function ModalMovie({ itemId }) {
  const [movie, setMovie] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchUrl = `/movie/${itemId}?api_key=${API_KEY}&language=de-DE`;

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setMovie(request.data);
      setIsLoaded(true);
      return request;
    }

    fetchData();
  }, [fetchUrl]);

  const shortText = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  const imageUrl = "https://image.tmdb.org/t/p/original";
  const release = new Date(movie?.release_date || movie?.first_air_date);
  const options = { year: "numeric" };

  return (
    <>
      {isLoaded ? (
        <div className="modal-movie">
          <div
            className="modal-movie-img"
            style={{
              backgroundImage: `url("${imageUrl}${movie?.backdrop_path}")`,
            }}
          >
            <div className="modal-movie-content">
              <div className="modal-movie-title">
                <h6>{movie?.title || movie?.name || movie?.original_name}</h6>
              </div>
              <div className="modal-movie-info">
                <ul>
                  <li>
                    <i className="fas fa-star"></i> {movie?.vote_average}
                  </li>
                  <li>
                    <i className="fas fa-heart"></i> {movie?.vote_count}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container modal-movie-content-bottom">
            <div className="row">
              <div className="col-md-8 left-content">
                <ul>
                  <li>
                    <i className="fas fa-calendar-alt"></i>{" "}
                    <span className="stage__release">
                      {release.toLocaleDateString("de-DE", options)}
                    </span>
                  </li>
                  <li>Dauer: {movie?.runtime} min</li>
                </ul>
                {movie.overview === "" ? (
                  <p>Leider kein Text vorhanden.</p>
                ) : (
                  <p>{shortText(movie?.overview, 350)}</p>
                )}
                <Link to={`/filme/${movie.id}`}>
                  Zum Cast <i className="fas fa-chevron-right"></i>
                </Link>
              </div>
              <div className="col-md-4 right-content">
                <h6>Genres:</h6>
                <ul className="genres">
                  {movie.genres.map((genre, index) => (
                    <li key={index}>{genre.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loadingspinner />
      )}
    </>
  );
}

export default ModalMovie;
