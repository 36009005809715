import MovieRoom from "./MovieRoom";
// import { AuthProvider } from "./service/auth";

function App() {
  return (
    // <AuthProvider>
      <MovieRoom />
    // </AuthProvider>
  );
}

export default App;
