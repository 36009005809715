import React, { useRef, useState } from "react";
import { useAuth } from "../service/auth";
import { Link, useHistory } from "react-router-dom";
import bg from "../img/bg.jpg";

function Registrieren() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch {
      setError("Registrierung fehlgeschlagen!");
      setLoading(false);
    }
  }

  return (
    <section
      className="login-signup"
      style={{ backgroundImage: `url("${bg}")` }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5">
            <div className="login-signup-form">
              <div className="row align-items-center">
                <div className="col-md-6 text-left">
                  <h2>
                    Movie <span>Room</span>
                  </h2>
                </div>
                <div className="col-md-6 text-right">
                  <h2>Registrieren</h2>
                </div>
              </div>
              {error && <p className="error">{error}</p>}
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  required
                  placeholder="E-Mail"
                  ref={emailRef}
                />
                <input
                  type="password"
                  required
                  placeholder="Passwort"
                  ref={passwordRef}
                />
                <button disabled={loading} type="submit">
                  Registrieren
                </button>
              </form>
              <div className="create-account">
                <p>
                  Zurück zum <Link to="/login">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Registrieren;
