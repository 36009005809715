import axios from "../service/axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loadingspinner from "./Loadingspinner";

function Persons({ title, fetchUrl }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [persons, setPersons] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setPersons(request.data.results);
      setIsLoaded(true);
      return request;
    }

    fetchData();
  }, [fetchUrl]);

  const image_url = "https://image.tmdb.org/t/p/w500";

  return (
    <section className="persons">
      <div className="container">
        <div className="row">
          {isLoaded ? (
            <>
              <div className="col-md-12">
                <h3>{title}</h3>
              </div>
              {persons.map((person) => (
                <div className="col-md-3" key={person.name}>
                  <Link key={person.name} to={`/personen/${person.id}`}>
                    <div className="persons__img">
                      <img
                        src={`${image_url}${person?.profile_path}`}
                        alt={person.name}
                      />
                    </div>
                    <p>{person?.name}</p>
                  </Link>
                </div>
              ))}
            </>
          ) : (
            <Loadingspinner />
          )}
        </div>
      </div>
    </section>
  );
}

export default Persons;
