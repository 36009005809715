import React, { useState } from "react";
import { useAuth } from "../service/auth";
import { useHistory } from "react-router-dom";
import bg from "../img/bg.jpg";

function Profil() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Logout fehlgeschlagen!");
    }
  }

  return (
    <section className="profil" style={{ backgroundImage: `url("${bg}")` }}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5">
            <div className="profil-box">
              <h2>Profil</h2>
              {error && <p className="error">{error}</p>}
              <ul>
                <li>
                  <strong>Email:</strong> {currentUser.email}
                </li>
              </ul>
              <button onClick={handleLogout}>Logout</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profil;
