import Row from "../components/Row";
import Stage from "../components/Stage";
import requests from "../service/requests";

function Serien() {
  return (
    <>
      <Stage title="Gerade Angesagt" fetchUrl={requests.fetchTvPopular} serien />
      <Row title="Netflix Originals" fetchUrl={requests.fetchNetflixOriginals} large serie />
      <Row title="Amazon Prime" fetchUrl={requests.fetchAmazon} serie />
      <Row title="Disney+" fetchUrl={requests.fetchDisneyPlus} serie />
      <Row title="Joyn" fetchUrl={requests.fetchJoyn} serie />
      <Row title="Sky Cinema" fetchUrl={requests.fetchSkyCinema} serie />
    </>
  );
}

export default Serien;
