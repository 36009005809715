import axios from "../service/axios";
import { useEffect, useState } from "react";
import SwiperCore, { Virtual, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { Modal } from "react-bootstrap";
import ModalMovie from "./ModalMovie";
import ModalTv from "./ModalTv";
import Loadingspinner from "./Loadingspinner";

SwiperCore.use([Virtual, Navigation]);

function Row({ title, fetchUrl, large = false, serie = false }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [movies, setMovies] = useState([]);
  const [show, setShow] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setMovies(request.data.results);
      setIsLoaded(true);
      return request;
    }

    fetchData();
  }, [fetchUrl]);

  const clickHandler = (index) => {
    setShow(index);
  };

  const hideModal = () => {
    setShow(null);
  };

  const image_url = "https://image.tmdb.org/t/p/w500";

  return (
    <section className={`${large ? "row-wrapper top" : "row-wrapper"}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>{title}</h3>
          </div>
        </div>
      </div>
      {isLoaded ? (
        <>
          {large ? (
            <>
              <Swiper
                className="movies"
                slidesPerView="auto"
                spaceBetween={35}
                watchSlidesVisibility={true}
                loop={true}
                loopedSlides={20}
                centeredSlides={true}
                navigation
              >
                {movies.map(
                  (movie, index) =>
                    ((large && movie.poster_path) ||
                      (!large && movie.backdrop_path)) && (
                      <SwiperSlide key={index}>
                        <div className="movies__content">
                          <div
                            className="button"
                            onClick={() => clickHandler(index)}
                          >
                            <div className="movies__img">
                              <img
                                src={`${image_url}${
                                  large
                                    ? movie.poster_path
                                    : movie.backdrop_path
                                }`}
                                alt={
                                  movie.name ||
                                  movie.title ||
                                  movie.orginal_name
                                }
                              />
                            </div>
                            <h5>
                              {movie.name || movie.title || movie.orginal_name}
                            </h5>
                            <ul>
                              <li className="left">
                                <i className="fas fa-star"></i>{" "}
                                {movie?.vote_average}
                              </li>
                              <li className="right">
                                <i className="fas fa-heart"></i>{" "}
                                {movie?.vote_count}
                              </li>
                            </ul>
                          </div>
                          <Modal
                            className={index}
                            show={show === index}
                            onHide={hideModal}
                          >
                            <div
                              className="close-movie-modal"
                              onClick={hideModal}
                            >
                              <i className="fas fa-times"></i>
                            </div>
                            {serie ? (
                              <ModalTv itemId={movie.id} />
                            ) : (
                              <ModalMovie itemId={movie.id} />
                            )}
                          </Modal>
                        </div>
                      </SwiperSlide>
                    )
                )}
              </Swiper>
            </>
          ) : (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Swiper
                    className="movies"
                    slidesPerView={5}
                    spaceBetween={35}
                    watchSlidesVisibility={true}
                    loop={true}
                    navigation
                  >
                    {movies.map(
                      (movie, index) =>
                        ((large && movie.poster_path) ||
                          (!large && movie.backdrop_path)) && (
                          <SwiperSlide key={index}>
                            <div className="movies__content">
                              <div
                                className="button"
                                onClick={() => clickHandler(index)}
                              >
                                <div className="movies__img">
                                  <img
                                    src={`${image_url}${
                                      large
                                        ? movie.poster_path
                                        : movie.backdrop_path
                                    }`}
                                    alt={
                                      movie.name ||
                                      movie.title ||
                                      movie.orginal_name
                                    }
                                  />
                                </div>
                                <h5>
                                  {movie.name ||
                                    movie.title ||
                                    movie.orginal_name}
                                </h5>
                                <ul>
                                  <li className="left">
                                    <i className="fas fa-star"></i>{" "}
                                    {movie?.vote_average}
                                  </li>
                                  <li className="right">
                                    <i className="fas fa-heart"></i>{" "}
                                    {movie?.vote_count}
                                  </li>
                                </ul>
                              </div>
                              <Modal
                                className={index}
                                show={show === index}
                                onHide={hideModal}
                              >
                                <div
                                  className="close-movie-modal"
                                  onClick={hideModal}
                                >
                                  <i className="fas fa-times"></i>
                                </div>
                                {serie ? (
                                  <ModalTv itemId={movie.id} />
                                ) : (
                                  <ModalMovie itemId={movie.id} />
                                )}
                              </Modal>
                            </div>
                          </SwiperSlide>
                        )
                    )}
                  </Swiper>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <Loadingspinner />
        </>
      )}
    </section>
  );
}

export default Row;
